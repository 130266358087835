//邓亚鑫-V2.2.1
<template>
  <div class="page-">
    <section class="login-wrap">
      <section class="login-content">
        <div class="login-box">
        </div>
        <section class="login-form">
          <div v-if="!(state.isForget || state.isChange)">
            <div class="ispassLogin-title">{{ state.isPassLogin ? '密码登录' : '验证码登录' }}</div>
            <a-form ref="formRef" :model="formModel" :rules="rules">
              <div class="form-items" v-if="state.isPassLogin">
                <a-input
                  @change="handledPhoneChange"
                  :placeholder="state.isPassLogin ? '请输入账号' : '请输入手机号'"
                  class="form-input"
                  @keyup.enter="userLogin"
                  ref="inputbox"
                  :value="state.username"
                >
                  <template #prefix >
                    <user-outlined style="color: rgba(0, 0, 0, 0.45);"/>
                  </template>
                </a-input>
                <div class="box-input-password">
                  <a-input
                    v-model:value="state.password"
                    placeholder="请输入密码"
                    class="form-input last-input"
                    :type="state.isShowPassword ? '' : 'password'"
                    @keyup.enter="userLogin"
                  >
                  <template #prefix>
                    <LockOutlined style="color: rgba(0, 0, 0, 0.45);"/>
                  </template>
                </a-input>
                  <img
                    @click="showPassword"
                    class="img-visible"
                    :src="
                      state.isShowPassword
                        ? require('@/assets/images/login/visible.png')
                        : require('@/assets/images/login/novisible.png')
                    "
                    alt=""
                  />
                </div>
              </div>
              <div class="form-items" v-else>
                <a-input
                  @change="handledPhoneChange"
                  @keyup.enter="userLogin"
                  placeholder="请输入手机号"
                  class="form-input"
                  :value="state.username"
                >
                  <template #prefix >
                    <MobileOutlined style="color: rgba(0, 0, 0, 0.45);"/>
                    <!-- <Icon :icon="icon"></Icon> -->
                  </template>
                </a-input>
                <div class="posit">
                  <a-form-item name="captcha" class="form-item">
                    <a-input
                      :maxlength="6"
                      placeholder="请输入验证码"
                      class="form-input form-captcha"
                      @change="handledChange"
                      @keyup.enter="userLogin"
                    >
                    <template #prefix>
                      <MailOutlined style="color: rgba(0, 0, 0, 0.45);" />
                    </template>
                    </a-input>
                  </a-form-item>
                  <a-button
                    :disabled="state.captchaText !== '获取验证码'"
                    class="get-captcha"
                    @click="getCaptcha"
                    :style="state.captchaText !== '获取验证码' ? 'color:#999999' : ''"
                    >{{ state.captchaText }}</a-button
                  >
                </div>
              </div>
            </a-form>

            <!-- 操作按钮 -->
            <div class="operation-box">
              <p class="form-switch">
                <span style="cursor: pointer" @click="forgetPassword">忘记密码</span>
              </p>
              <p class="form-switch operation-right">
                <span style="cursor: pointer;" @click="switchLoginMode">{{
                  state.isPassLogin ? '手机验证码登录' : '账号密码登录'
                }}</span>
              </p>
            </div>
            <a-button :disabled="state.logining" class="form-btn" @click="userLogin">{{
              state.logining ? '登录中' : '登录'
            }}</a-button>
          </div>

          <!-- 忘记密码 -->
          <div class="forget-box" v-else>
            <div class="forget-center">
              <!-- 标题 -->
              <div class="forget-title">{{ state.isForget ? '忘记密码' : '修改密码' }}</div>
              <!-- 输入框 -->
              <a-form ref="formRef" :model="formModel" :rules="rules">
                <div class="form-items">
                  <a-input
                    @change="handledPhoneChange"
                    @keyup.enter="forgetSumbit"
                    placeholder="请输入账号"
                    class="form-input"
                    autocomplete="new-password"
                    style="margin-bottom: 1.75rem;"
                    :value="state.username"
                  >
                  <template #prefix>
                    <user-outlined style="color: rgba(0, 0, 0, 0.45);"/>
                  </template>
                  </a-input>
                  <div class="posit">
                    <a-form-item name="captcha" class="form-item">
                      <a-input
                        :maxlength="6"
                        placeholder="请输入验证码"
                        autocomplete="new-password"
                        class="form-input form-captcha"
                        @change="handledChange"
                        @keyup.enter="forgetSumbit"
                      >
                        <template #prefix>
                          <MailOutlined style="color: rgba(0, 0, 0, 0.45);"/>
                        </template>
                      </a-input>
                    </a-form-item>
                    <a-button
                      :disabled="state.captchaText !== '获取验证码'"
                      class="get-captcha"
                      @click="getCaptcha"
                      :style="state.captchaText !== '获取验证码' ? 'color:#999999' : ''"
                      >{{ state.captchaText }}</a-button
                    >
                  </div>
                  <a-form-item v-show="false" class="form-item">
                    <a-input
                      placeholder="请输入账号"
                      autocomplete="new-password"
                      :value="state.username"
                      name="username"
                    >
                      <template #prefix>
                        <LockOutlined style="color: rgba(0, 0, 0, 0.45);"/>
                      </template>
                    </a-input>
                  </a-form-item>
                  <!-- @change="state.setPassword = state.setPassword.replace(/\W+/g, '')" -->
                  <a-input-password
                    v-model:value="state.setPassword"
                    placeholder="请输入新密码"
                    :class="['form-input', state.textShow && 'nomargin-input']"
                    style="margin: 1rem 0 0 0"
                    type="password"
                    autocomplete="new-password"
                    @keyup.enter="forgetSumbit"
                    maxlength="16"
                    @focus="state.textShow = true"
                    @blur="state.textShow = false"
                  >
                    <template #prefix>
                      <LockOutlined style="color: rgba(0, 0, 0, 0.45);"/>
                    </template>
                  </a-input-password>
                  <div class="password-text" v-show="state.textShow">
                    密码必须是8-16位的英文字母、数字、字符组成，且至少含3种以上字符
                  </div>
                  <!-- @change="state.againPassword = state.againPassword.replace(/\W+/g, '')" -->
                  <a-input-password
                    v-model:value="state.againPassword"
                    placeholder="请再次输入新密码"
                    class="form-input form-bottom"
                    type="password"
                    autocomplete="new-password"
                    @keyup.enter="forgetSumbit"
                    maxlength="16"
                    style="margin: 1.75rem 0;"
                  >
                  <template #prefix>
                      <LockOutlined style="color: rgba(0, 0, 0, 0.45);"/>
                    </template>
                  </a-input-password>
                </div>
              </a-form>
              <div class="form-btns">
                <a-button @click="forgetGetback" class="go-back">返回</a-button>
                <a-button @click="forgetSumbit" class="form-btn btn-outline" :disabled="state.forgetLoading">{{
                  state.forgetLoading ? '修改中' : '确定'
                }}</a-button>
              </div>
            </div>
          </div>
          <!-- 企业微信 -->
          <!-- <div class="qy-wx" v-else>
            <div class="img-list">
              <img class="qy-wechat" src="@/assets/images/qy-wechat.png" alt="" />
              <img class="right-icon" src="@/assets/images/right.png" alt="" />
              <img class="yda-imgs" src="@/assets/images/yda-imgs.png" alt="" />
            </div>
            <div class="text-box">登录开启您的安全管家</div>
            <div class="authorization-button" @click="qyWechatLogin">授权当前企业微信一键登录</div>
          </div> -->
        </section>
      </section>
      <company-modal
        v-model:visible="state.enterpriseVisible"
        :allEnterprise="state.allEnterprise"
        @select-enterprise="selectEnterprise"
      />
    </section>
    <div class="warning-dailog" v-if="state.warningDailog">
      <div class="warning-box">
        <img src="@/assets/images/close-icon.png" class="close-btn" alt="" @click="state.warningDailog = false" />
        <div class="image-box">
          <img src="@/assets/images/secure-img.png" alt="" />
        </div>
        <div class="title-box">
          应国家密码法规定，系统为了规范密码应用和管理以及保障您的个人权益，检测到您的密码过于简单存在使用风险，请修改密码。
        </div>
        <div class="bottom-btn" @click="goChangePassword">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, defineComponent, ref, computed, onMounted, watch, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import { passLogin, getAccountCaptcha, authorize, loginApi, getUserInfo, setForgetPassword } from '@/apis/user'
import { useStore } from 'vuex'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { SET_ROUTER } from '@/store/router/mutations-type'
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import companyModal from '@/views/User/components/companyModal'
import md5 from 'js-md5'
import { ToCDB } from '@/utils/utils'
import axios from 'axios' // 引用axios
import { encryption, getEncryptedString } from '@/utils/encryption/aesEncrypt'
import { UserOutlined, TabletOutlined,LockOutlined,MobileOutlined,MailOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'Login',
  components: {
    companyModal,
    UserOutlined, 
    TabletOutlined,
    LockOutlined,
    MobileOutlined,
    MailOutlined
  },
  setup() {
    onMounted(() => {
      // encryptType = 1
      // const reg1 = new RegExp('')
      // var regs = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,16}$/
      // // var regs = /^(?![a-z\\d]+$)\S{8,16}$/
      // console.log('1111111111111111', regs.test('qqq11w1E'));
      setSessionId()
      getDev()
      // qyWechatLogin()

      // var reg = /^(192.168.1.)/
      // if (window.location.hostname !== 'localhost' && !reg.test(window.location.hostname)) {
      //   // 判断非本地server时 http强制转换成https
      //   var targetProtocol = 'https:'
      //   if (window.location.protocol != targetProtocol) {
      //     window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length)
      //   }
      // }
    })
    const inputbox = ref(null)
    const icp = window.icp
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      username: undefined,
      password: undefined,
      captcha: undefined,
      isPassLogin: true,
      isForget: false, // 是否是忘记密码
      isChange: false, // 是否是修改密码
      setPassword: undefined, // 设置密码
      againPassword: undefined, // 再次确认密码
      forgetLoading: false, // 忘记密码loading
      captchaText: '获取验证码',
      allEnterprise: [],
      enterpriseVisible: false,
      userInfo: undefined,
      logining: false,
      redirect: undefined,
      timer: null,
      isFirstLogin: true, //true-首次，false-再次
      isWeChatWork: false,
      warningDailog: false, // 警告弹窗
      textShow: false, // 字体
      prevUserName: '',
      isShowPassword: false //密码是否明文
    })
    const formRef = ref(null)
    const rules = {
      captcha: [
        {
          pattern: /^[0-9]*$/,
          message: '验证码格式不正确'
        }
      ]
    }
    let encryptedString = null
    const formModel = reactive({
      captcha: undefined
    })

    const cmsNotice = (type, contant, duration = 4) => {
      notification[type]({
        message: '提示',
        description: contant,
        duration
      })
    }

    // 警告弹窗确定
    const goChangePassword = () => {
      state.warningDailog = false
      state.isChange = true
    }

    // 存储sessionid
    const setSessionId = async () => {
      console.log('12212121', getLocation('sessionId'))
      if (getLocation('sessionId')) {
        localStorage.setItem('sessionId', getLocation('sessionId'))
      } else {
        return
      }
      console.log(localStorage.getItem('sessionId'), 'sesssssssssss')
      const res = await getUserInfo()

      const { data } = res
      if (res.success) {
        // 设置用户信息
        state.userInfo = {
          ...data,
          userName: data.userName,
          userId: data.result.staffId,
          id: data.userId,
          sexName: data.sexName,
          avatar: data.avatar,
          allEnterprise: data.result.allEnterprise,
          mobile: data.mobile,
          nickName: data.nickname,
          enterpriseBindLibawall: data.result.enterpriseBindLibawall,
          isNotInitPassword: data.isNotInitPassword,
          enterpriseId: data.result.enterpriseId,
          identity: data.identity
        }
        state.timer && clearInterval(state.timer)
        if (data.result.allEnterprise.length <= 1) {
          state.userInfo = {
            ...state.userInfo,
            enterpriseName: data.result.enterpriseName,
            enterpriseId: data.result.enterpriseId
          }
          saveUserData(data.userId, data.result.enterpriseId)
          await cacheUserInfo()
          validateRedirectPath()
          cmsNotice('success', '登录成功')
        } else {
          // 企业弹窗是否弹出
          userCpData(data)
          state.allEnterprise = data.result.allEnterprise
          if (state.isFirstLogin) {
            state.enterpriseVisible = true
          } else {
            state.userInfo = {
              ...state.userInfo,
              enterpriseName: data.result.enterpriseName,
              enterpriseId: data.result.enterpriseId
            }
            await cacheUserInfo()
            validateRedirectPath()
            cmsNotice('success', '登录成功')
          }
        }
        await router.push('/')
      }
    }

    //获取地址栏
    const getLocation = name => {
      // 未传参，返回空
      if (!name) return null
      // 查询参数：先通过search取值，如果取不到就通过hash来取
      var after = window.location.search
      after = after.substr(1) || window.location.hash.split('?')[1]
      // 地址栏URL没有查询参数，返回空
      if (!after) return null
      // 如果查询参数中没有"name"，返回空
      if (after.indexOf(name) === -1) return null

      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      // 当地址栏参数存在中文时，需要解码，不然会乱码
      var r = decodeURI(after).match(reg)
      // 如果url中"name"没有值，返回空
      if (!r) return null

      return r[2]
    }
    const getDev = () => {
      const isMobile = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ) // 是否手机端
      const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
      if (isComWx && !isMobile) {
        //PC端企业微信
        // 在 request.js 作了拦截，这里是双重保险
        state.isWeChatWork = true
        window.location.href = process.env.VUE_APP_QYWX_URL

        // if (getLocation("isToSetPhone")) {
        //   router.push('/binding');
        // }
        // let id = getLocation("enterpriseId");
        // console.log(id,'id');
        return 'com-wx-pc'
      }
    }

    //cookies
    // process.env.VUE_APP_API_BASE_URL
    const aData = {
      snsapi_privateinfo: 'snsapi_privateinfo',
      redirectUri: window.location.href,
      state: ''
    }
    // 企业微信授权
    const qyWechatLogin = async () => {
      // router.push('/')
      //授权
      // axios.get(`https://ydatestapi.libawall.com/wechatLogin/author/authorize?scope=${aData.snsapi_privateinfo}&redirectUri=${aData.redirectUri}&state=${aData.state}`).then(res => {
      //   console.log('res', res)
      //   console.log('res.data.code ', res.data.code == 0)
      //   window.location.href = res.data.data;
      //   // window.location.href = window.location.href;
      //   // if (res.data.code == 0) {
      //   //   console.log("res", res);
      //   // }
      //   // console.log('211221', process.env.VUE_APP_API_BASE_URL)
      // })
      // const res = await authorize(
      //   aData.snsapi_privateinfo,
      //   aData.redirectUri,
      //   aData.state
      // );
    }

    // 忘记密码
    const forgetPassword = () => {
      state.isForget = true
      //账号切换携带处理
      state.prevUserName = state.username != undefined && state.username != '' ? state.username : state.prevUserName
      state.username = isNaN(state.username) ? undefined : state.username
      // 清空定时器
      clearTimer()
    }

    // 提交忘记密码
    const forgetSumbit = () => {
      if (!state.username) {
        cmsNotice('error', '请输入账号')
        return
      }
      formRef.value.validate().then(async () => {
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(state.username)) {
          cmsNotice('error', '请输入正确的手机号')
          return
        }
        if (!formModel.captcha) {
          cmsNotice('error', '请输入验证码')
          return
        }
        if (formModel.captcha.length !== 6) {
          cmsNotice('error', '验证码错误')
          return
        }
        // if (state.captcha && state.captcha !== formModel.captcha) {
        //   cmsNotice('error', '验证码错误')
        //   return
        // }
        if (!state.setPassword) {
          cmsNotice('error', '请输入密码')
          return
        }
        // if (state.setPassword.length < 6) {
        //   cmsNotice('error', '密码不可低于6个字符')
        //   return
        // }
        if (!state.againPassword) {
          cmsNotice('error', '确认密码不能为空')
          return
        }
        let regs = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,16}$/
        if (!regs.test(state.againPassword)) {
          cmsNotice('error', '密码必须是8-16位的英文字母、数字、字符组成，且至少含3种以上字符')
          return
        }
        if (state.againPassword != state.setPassword) {
          cmsNotice('error', '两次输入密码不一致')
          return
        }

        let params = {
          phone: state.username.trim(),
          note: formModel.captcha.trim(),
          newPassWord: state.againPassword.trim()
        }
        state.forgetLoading = true
        const res = await setForgetPassword(params)
        console.log('res', res)
        if (res.code === 0) {
          cmsNotice('success', '修改成功')
          state.isForget = false
          state.isChange = false
          state.forgetLoading = false
          // 清空定时器
          clearTimer()
        } else {
          // state.isForget = false
          state.forgetLoading = false
        }
      })
    }

    // 返回登录状态
    const forgetGetback = () => {
      state.isForget = false
      state.isChange = false
      //账号切换携带处理

      state.username = state.username == '' || state.username == undefined ? state.prevUserName : state.username
      // 清空定时器
      clearTimer()
    }

    // 清空获取验证码定时器方法（包括重置账号密码）
    const clearTimer = () => {
      // state.username = undefined
      state.password = undefined
      if (state.timer) {
        clearInterval(state.timer)
        state.captchaText = '获取验证码'
      }
      state.isPassLogin = true
      // state.isPassLogin = false
      if (state.isForget || state.isChange) {
        formModel.captcha = undefined
        state.setPassword = undefined
        state.againPassword = undefined
      }
    }

    const lData = {
      state: '',
      code: '',
      json: 1,
      loginType: 100,
      'remember-me': true,
      from: 5
    }
    //登录
    const changeLogin = async () => {
      if (localStorage.getItem('sessionId') == '') {
        console.log('123')
        lData.state = getLocation('state')
        lData.code = getLocation('code')
        const res = await loginApi(lData)
        console.log('登录res', res)
        if (res && res.code === 0) {
          localStorage.setItem('sessionId', res.data.sessionId)
          localStorage.setItem('yda-admin-userInfo', res.data)
          console.log('res', res)
          console.log('staffId', res.data.result.staffId)
          router.push('/')
        }
      }
    }

    //获取密码加密规则
    function getEncrypte() {
      getEncryptedString().then(data => {
        encryptedString = data
        console.log('encryptedString', encryptedString)
      })
    }
    // getEncrypte()

    const cacheUserInfo = async () => {
      // 保存用户信息
      localStorage.setItem('yda-admin-userInfo', JSON.stringify(state.userInfo))
      store.commit(`user/${SET_USER_INFO}`, state.userInfo)
      await new Promise(resolve => {
        store.dispatch(`router/${SET_ROUTER}`, resolve)
      })
      store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`)
    }

    const userCpData = res => {
      //存储用户企业数据
      let userCp = JSON.parse(localStorage.getItem('yda-user-data')) || {}
      if (res.userId in userCp) {
        let isHavCp = res.result.allEnterprise.filter(val => val.id == userCp[res.userId])
        if (isHavCp.length) {
          state.isFirstLogin = false
        } else {
          state.isFirstLogin = true
        }
      } else {
        state.isFirstLogin = true
      }
    }

    const validateRedirectPath = () => {
      // 检验跳转地址
      const routerObj = router.getRoutes()
      let isHasHome = false
      routerObj.forEach(item => {
        if (item.path === '/dashboard/analysis') {
          isHasHome = true
        }
      })
      router.push(isHasHome ? '/dashboard/analysis' : '/seal/apply')
    }

    const setUserInfo = async res => {
      console.log('-----', res)
      // 设置用户信息
      state.userInfo = {
        ...res,
        userName: res.userName,
        userId: res.result.staffId,
        id: res.userId,
        sexName: res.sexName,
        avatar: res.avatar,
        allEnterprise: res.result.allEnterprise,
        mobile: res.mobile,
        nickName: res.nickname,
        enterpriseBindLibawall: res.result.enterpriseBindLibawall,
        isNotInitPassword: res.isNotInitPassword,
        enterpriseId: res.result.enterpriseId,
        identity: res.identity
      }
      console.log('state.userInfo', state.userInfo)
      state.timer && clearInterval(state.timer)
      if (res.result.allEnterprise.length <= 1) {
        state.userInfo = {
          ...state.userInfo,
          enterpriseName: res.result.enterpriseName,
          enterpriseId: res.result.enterpriseId
        }
        saveUserData(res.userId, res.result.enterpriseId)
        await cacheUserInfo()
        validateRedirectPath()
        cmsNotice('success', '登录成功')
      } else {
        // 企业弹窗是否弹出
        userCpData(res)
        state.allEnterprise = res.result.allEnterprise
        if (state.isFirstLogin) {
          state.enterpriseVisible = true
        } else {
          state.userInfo = {
            ...state.userInfo,
            enterpriseName: res.result.enterpriseName,
            enterpriseId: res.result.enterpriseId
          }
          await cacheUserInfo()
          validateRedirectPath()
          cmsNotice('success', '登录成功')
        }
      }
    }

    const saveUserData = (userId, enterpriseId) => {
      let userCp = JSON.parse(localStorage.getItem('yda-user-data')) || {}
      userCp[userId] = enterpriseId
      localStorage.setItem('yda-user-data', JSON.stringify(userCp))
    }

    const selectEnterprise = async enterpriseInfo => {
      // 选择企业
      const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
      userInfo && (state.userInfo = userInfo)
      state.userInfo = {
        ...state.userInfo,
        ...enterpriseInfo
      }
      saveUserData(enterpriseInfo.id, enterpriseInfo.enterpriseId)
      await cacheUserInfo()
      validateRedirectPath()
      state.enterpriseVisible = false
      cmsNotice('success', '登录成功', 0.5)
    }
    const loginFn = () => {
      state.logining = true
      const params = {
        username: state.username.trim(),
        json: 1,
        // 3-账户密码，1-验证码
        loginType: 3,
        from: 1,
        source: 1,
        encrypt: false,
        'remember-me': true,
        encryptType: 1
      }
      if (state.isPassLogin) {
        // params.password = md5(state.password.trim())
        params.password = encryption(state.password.trim())
        params.encrypt = true
      } else {
        params.note = formModel.captcha.trim()
        params.loginType = 1
      }
      passLogin(params)
        .then(res => {
          if (res.success) {
            setUserInfo(res.data)
          } else {
            console.log('走到了catch', res)
            if (res.code == 2112) {
              state.warningDailog = true
            }
          }
        })
        .finally(() => (state.logining = false))
    }
    const pwdLogin = () => {
      // 密码登录
      if (!state.password) {
        cmsNotice('error', '请输入密码')
        return
      }
      loginFn()
    }

    const captchaLogin = () => {
      // 验证码登录
      if (!formModel.captcha) {
        cmsNotice('error', '请输入验证码')
        return
      }
      if (formModel.captcha.length !== 6) {
        cmsNotice('error', '验证码错误')
        return
      }
      if (state.captcha && state.captcha !== formModel.captcha) {
        cmsNotice('error', '验证码错误')
        return
      }
      loginFn()
    }

    const getCaptcha = async () => {
      // 获取验证码
      if (!state.username) {
        cmsNotice('error', '请输入账号')
        return
      }
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(state.username)) {
        cmsNotice('error', '请输入正确的手机号')
        return
      }
      // let num = 59
      // state.captchaText = `${num--}s`
      // state.timer = setInterval(() => {
      //   if (num === -1) {
      //     clearInterval(state.timer)
      //     state.captchaText = '获取验证码'
      //     return
      //   }
      //   state.captchaText = `重新获取(${num--}s)`
      // }, 1000)
      const res = await getAccountCaptcha({
        phone: state.username,
        code: state.isForget || state.isChange ? 'FORGET_PASSWORD' : 'LOGIN_SEND'
      })
      if (res.success) {
        let num = 59
        state.timer = setInterval(() => {
          if (num === -1) {
            clearInterval(state.timer)
            state.captchaText = '获取验证码'
            return
          }
          state.captchaText = `重新获取(${num--}s)`
        }, 1000)
      }
      state.captcha = res.data ? res.data + '' : null
    }

    const userLogin = () => {
      // 登录
      if (!state.username) {
        cmsNotice('error', '请输入账号')
        return
      }
      formRef.value.validate().then(() => {
        state.isPassLogin ? pwdLogin() : captchaLogin()
      })
    }

    const switchLoginMode = () => {   //
      console.log(state.isPassLogin)
      // 切换登录方式
      if (state.isPassLogin) {
        //账号切换携带处理
        state.prevUserName = state.username
        state.username = isNaN(state.username) ? undefined : state.username
        state.password = undefined
        if (state.timer) {
          clearInterval(state.timer)
          state.captchaText = '获取验证码'
        }
        state.isPassLogin = false
      } else {
        // state.username = undefined
        //账号切换携带处理
        state.username = state.username == '' || state.username == undefined ? state.prevUserName : state.username
        state.prevUserName = state.username
        state.captcha = undefined
        state.isPassLogin = true
        formModel.captcha = undefined
      }
    }
    // 监视验证码：报错
    // watch(
    //   ()=>formModel.captcha,
    //   (newValue,oldValue)=>{
    //     var reg = new RegExp("^[0-9]*$");
    //     if(!reg.test(newValue)){
    //       formModel.captcha = oldValue
    //       // console.log(111);
    //     }
    //   }
    // )
    //全角转半角
    const handledChange = e => {
      formModel.captcha = ToCDB(e.target.value)
    }
    const handledPhoneChange = e => {
      state.username = ToCDB(e.target.value)
      if (state.isPassLogin) {
        if (state.isForget) {
          state.username = state.username.replace(/[^0-9.]/g, '')
          return
        }
        //state.username = state.username.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, '')
        state.username = state.username
      } else {
        state.username = state.username.replace(/[^0-9.]/g, '')
      }
    }
    // 密码明文切换
    const showPassword = () => {
      state.isShowPassword = !state.isShowPassword
    }


    return {
      state,
      userLogin,
      pwdLogin,
      switchLoginMode,
      getCaptcha,
      formRef,
      rules,
      formModel,
      selectEnterprise,
      handledChange,
      qyWechatLogin,
      handledPhoneChange,
      forgetPassword,
      forgetSumbit,
      forgetGetback,
      inputbox,
      goChangePassword,
      showPassword,
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .ant-form-item {
  margin-bottom: 0;
  margin-top: 0;
  .ant-form-item-control {
    line-height: 0;
  }
}


// ::v-deep .ant-input:focus{
//   box-shadow: none !important;
// }

// ::v-deep .ant-input-affix-wrapper-focused{
//   box-shadow: none !important;
// }

// ::v-deep .ant-btn:hover{
//   color: #000000;
// }


.login-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/images/newlogin/bgimg1.png') 50% no-repeat;
  background-size: cover;
  .login-content {
    width: 69.5rem;
    height: 40.625rem;
    opacity: 1;
    // background: #ffffff;
    border-radius:15px;
    display: flex;
    // justify-content: space-between;
    overflow: hidden;
    // box-shadow: 0px 2px 32px 0px rgba(135, 138, 152, 0.19);
    .login-box {
      width: 37.625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      background: url('../../assets/images/newlogin/login-bg1.png') 50% no-repeat;
      background-size: cover;
    }
    .login-figure {
      width: 40.875rem;
    }
    .login-brand {
      position: absolute;
      top: 36px;
      left: 2.375rem;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #131314;
      text-shadow: 0px 2px 32px rgba(135, 138, 152, 0.19);
    }
    .login-effect {
      position: absolute;
      top: 65px;
      left: 2.375rem;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #323233;
      text-shadow: 0px 2px 32px rgba(135, 138, 152, 0.19);
    }
    .login-form {
      flex: 1;
      width: 33.1875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: -5px;
      background: #fff;
      // transform: translateX(-3.125rem);
      // padding-left: 2rem;
      .form-title {
        width: 17.375rem;
        // height: 11.5625rem;
        display: block;
        // margin: 2.5625rem 6.375rem 1.875rem 1.3125rem;
        margin-top: 2.75rem;
        margin-bottom: 3.75rem;
      }
      .form-items {
        width: 20rem;
      }
      .form-item {
        width: 20rem;
        border: none;
        // margin-bottom: 0.75rem;
        border-bottom: 0.0625rem solid #e6e6e6;
      }
      .nomargin-input {
        margin-bottom: 0rem !important;
      }

      .form-input {
        width: 20rem;
        height: 2.5rem;
        margin-bottom: 2.5rem;
        font-size: 1rem;
        border: none;
        border-bottom: 0.0625rem solid #e6e6e6;
        color: #000000;
      }
      .last-input {
        margin-bottom: 0.75rem;
      }
      a .form-input:focus {
        box-shadow: none;
      }
      .form-captcha {
        width: 10rem;
        margin-bottom: 0rem;
        border: none;
        font-size: 1rem;
      }
      .get-captcha {
        width: 7.5rem;
        height: 2.5rem;
        font-size: 1rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        // text-align: right;
        color: #333333;
        line-height: 2.5rem;
        border: none;
        // margin-left: 2.5rem;
        background: transparent;
      }
      .form-btn {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        margin-top: 2.5rem;
        width: 20rem;
        height: 2.5rem;
        background: #1659a0;
        border-radius: 0.125rem;
        box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.1), 0 0.5rem 0.625rem 0 rgba(33, 26, 107, 0.2);
        border: none;
        margin-bottom: 5.875rem;
        font-size: 1rem;
        // text-shadow: 0px 0.125rem 2rem 0px rgba(0,0,0,0.10), 0 0.5rem 0.625rem 0 rgba(208,24,55,0.20);
      }
      .ispassLogin-title{
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: #131314;
        line-height: 24px;
        text-shadow: 0px 2px 32px rgba(135, 138, 152, 0.19);
        margin-bottom: 2.0625rem;
      }

      // 操作按钮
      .operation-box {
        width: 20rem;
        display: flex;

        .form-switch {
          font-size: 0.875rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #0A7BFF;
          line-height: 1.25rem; 
          width: 20rem;
        }

        .operation-right {
          text-align: right;
        }
      }

      // 忘记密码
      .forget-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        // align-items: center;

        .forget-center {
          width: 20rem;
          height: 22.6875rem;
          // background-color: skyblue;
          margin-top: 7.0625rem;

          .forget-title {
            height: 24px;
            font-size: 18px;
            font-weight: 600;
            color: #131314;
            line-height: 24px;
            text-shadow: 0px 2px 32px rgba(135, 138, 152, 0.19);
            margin-bottom: 2.0625rem;
          }

          // 返回 - 确定
          .form-btns {
            width: 17.5rem;
            margin: auto;
            display: flex;
            justify-content: space-between;

            .go-back {
              width: 7.5rem;
              height: 2.5rem;
              background: #ffffff;
              box-shadow: 0px 2px 32px 0px rgba(135, 138, 152, 0.19);
              border-radius: 2px;
              border: 1px solid #d9d9d9;
              cursor: pointer;
            }

            .btn-outline {
              width: 7.5rem;
              height: 2.5rem;
              box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.1), 0 0.5rem 0.625rem 0 rgba(33, 26, 107, 0.2);

              border-radius: 0.125rem;
              margin: 0;
              // cursor: pointer;
            }
          }
        }
      }
    }
  }
}

// 企业微信
.qy-wx {
  width: 320px;
  height: 183px;
  margin: 211px 0 176px 0;

  .img-list {
    width: 173px;
    display: flex;
    margin: auto;

    .qy-wechat {
      width: 58px;
      height: 57px;
    }
    .right-icon {
      width: 40px;
      height: 26px;
      margin: 16px 21px 0 6px;
    }
    .yda-imgs {
      width: 48px;
      height: 48px;
      margin-top: 5px;
    }
  }

  .text-box {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #131314;
    text-align: center;
    line-height: 25px;
    text-shadow: 0px 2px 32px rgba(135, 138, 152, 0.19);
    margin: 22px 0 44px 0;
  }

  .authorization-button {
    width: 320px;
    height: 40px;
    background: #0A7BFF;
    box-shadow: 0px 2px 32px 0px rgba(135, 138, 152, 0.19), 0px 8px 10px 0px rgba(208, 24, 55, 0.2);
    border-radius: 2px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px rgb(250, 255, 189) inset !important;
}
.posit {
  position: relative;
  .get-captcha {
    position: absolute;
    bottom: 0rem;
    right: -0.9375rem;
  }
}
:deep(.ant-form-item) {
  margin-bottom: 0.75rem;
}
.page-index {
  position: relative;
}
.warning-dailog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  // opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;

  .warning-box {
    width: 400px;
    height: 294px;
    background: #ffffff;
    background-image: url('../../assets/images/red-bg.png');
    background-size: cover;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05),
      0px 6px 16px -8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    position: relative;

    // opacity: 1;

    .close-btn {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 24px;
      top: 20px;
      cursor: pointer;
    }

    .image-box {
      width: 110px;
      height: 110px;
      background: #ffffff;
      margin-top: -40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 55px;

      img {
        width: 60px;
        height: 65px;
      }
    }
    .title-box {
      margin: 32px 30px 40px;
      line-height: 24px;
    }
    .bottom-btn {
      width: 220px;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 40px;
      text-shadow: 0px 8px 10px rgba(208, 24, 55, 0.2);
      // padding: .5625rem 5.6875rem;
      // margin: 0 5.625rem;
      background: #1659a0;
      // box-shadow: 0px 8px 10px 0px rgba(208, 24, 55, 0.2);
      box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.1), 0 0.5rem 0.625rem 0 rgba(33, 26, 107, 0.2);
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
.password-text {
  margin: 0.5rem 0 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: #333333;
  line-height: 1.25rem;
  text-shadow: 0px 0.125rem 2rem rgba(135, 138, 152, 0.19);
}
.box-input-password {
  position: relative;
  // border: 1px solid red;

  .img-visible {
    width: 17px;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>
